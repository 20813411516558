import React from 'react';
import {
  ComponentPropsWithoutRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useRouter } from 'next/router'

import { useMediaQuery } from 'react-responsive'

import { useCollections } from '@reservoir0x/reservoir-kit-ui'

import { useIntersectionObserver } from 'usehooks-ts'

import { useMounted } from '@alpha-shares/res-utils/hooks'
import supportedChains from '@alpha-shares/res-utils/chains'

import { Button } from '../Atoms';

import { ChainContext } from '@alpha-shares/react-components/Contexts/ChainContextProvider'
import { Text, Flex, Box } from '@alpha-shares/react-components/primitives'

import ChainToggle from '@alpha-shares/react-components/common/ChainToggle'
import LoadingSpinner from '@alpha-shares/react-components/common/LoadingSpinner'
import CollectionsTimeDropdown, {
  CollectionsSortingOption,
} from '@alpha-shares/react-components/common/CollectionsTimeDropdown'
import { CollectionRankingsTable } from '@alpha-shares/react-components/rankings/CollectionRankingsTable'

type TrendingCollectionsTableProps = {
  infiniteScroll?: boolean
}

export const TrendingCollectionsTable:React.FC<TrendingCollectionsTableProps> = ({ infiniteScroll = false }) => {
  const router = useRouter()
  const isSSR = typeof window === 'undefined'
  const isMounted = useMounted()
  const compactToggleNames = useMediaQuery({ query: '(max-width: 800px)' })
  const [sortByTime, setSortByTime] =
    useState<CollectionsSortingOption>('1DayVolume')

  const { chain } = useContext(ChainContext)

  let collectionQuery: Parameters<typeof useCollections>['0'] = {
    limit: 10,
    sortBy: sortByTime,
    includeMintStages: true,
  }

  

  // useEffect(() => {
  //   if (router.query.chain) {
  //     let chainIndex: number | undefined
  //     for (let i = 0; i < supportedChains.length; i++) {
  //       if (supportedChains[i].routePrefix == router.query.chain) {
  //         chainIndex = supportedChains[i].id
  //       }
  //     }
  //     if (chainIndex !== -1 && chainIndex) {
  //       switchCurrentChain(chainIndex)
  //     }
  //   }
  // }, [router.query])

  // if (chain.collectionSetId) {
  //   collectionQuery.collectionsSetId = chain.collectionSetId
  // } else if (chain.community) {
  //   collectionQuery.community = chain.community
  // }

  const { data, fetchNextPage, isFetchingPage, isValidating } = useCollections(
    collectionQuery,
    undefined,
    chain?.id
  )

  let collections = data || []

  const loadMoreRef = useRef<HTMLDivElement>(null)
  const loadMoreObserver = useIntersectionObserver(loadMoreRef, {})

  useEffect(() => {
    let isVisible = !!loadMoreObserver?.isIntersecting
    if (isVisible && infiniteScroll) {
      fetchNextPage()
    }
  }, [loadMoreObserver?.isIntersecting])

  let volumeKey: ComponentPropsWithoutRef<
    typeof CollectionRankingsTable
  >['volumeKey'] = 'allTime'

  switch (sortByTime) {
    case '1DayVolume':
      volumeKey = '1day'
      break
    case '7DayVolume':
      volumeKey = '7day'
      break
    case '30DayVolume':
      volumeKey = '30day'
      break
  }
  return (
    <Box
        css={{
          p: 24,
          height: '100%',
          '@bp800': {
            p: '$5',
          },

          '@xl': {
            px: '$6',
          },
        }}
      >
        <Flex direction="column">
          <Flex
            justify="between"
            align="start"
            css={{
              flexDirection: 'column',
              gap: 24,
              mb: '$4',
              '@bp800': {
                alignItems: 'center',
                flexDirection: 'row',
              },
            }}
          >
            <Text style="h4" as="h4">
              Trending Collections
            </Text>
            <Flex align="center" css={{ gap: '$4' }}>
              <CollectionsTimeDropdown
                compact={compactToggleNames && isMounted}
                option={sortByTime}
                onOptionSelected={(option) => {
                  setSortByTime(option)
                }}
              />
              <ChainToggle updateRouteWithChainSwitch={false} />
            </Flex>
          </Flex>
          {isSSR || !isMounted ? null : (
            <CollectionRankingsTable
              collections={collections}
              volumeKey={volumeKey}
              loading={isValidating}
            />
          )}
          {!infiniteScroll && (
            <div className='py-4 w-full'>
              <Button onClick={() => fetchNextPage()}>Load More</Button>
            </div>
          )}
          <Box
            ref={loadMoreRef}
            css={{
              display: isFetchingPage ? 'none' : 'block',
            }}
          ></Box>
        </Flex>
        {(isFetchingPage || isValidating) && (
          <Flex align="center" justify="center" css={{ py: '$4' }}>
            <LoadingSpinner />
          </Flex>
        )}
      </Box>
  )
}